html {
  background-color: #161a29;
}

h4 {
  margin-top: -5px;
  margin-bottom: 20px;
  font-weight: normal;
  color: #787b8f;
}

h1 {
  font-size: 20;
  margin-top: -5px;
  margin-bottom: 4px;
}

.release-notes-main-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  gap: 0.2em;
}

.release-notes-item-wrapper {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.release-notes-item-span {
  display: flex;
  flex: 1;
  margin-left: 0.4em;
}
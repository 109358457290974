/* p strong {
  display: block;
  font-size: 1.75em;
  padding-top: 1em;
  padding-bottom: 1em;
} */

.privacy-policy h1 {
  font-size: 1.4em;
  margin-top: 0.4em;
  margin-bottom: 0.4em;
}

.privacy-policy strong {
  font-weight: 900;
}

.privacy-policy {
  display: flex;
  flex-direction: column;
  gap: 0.9em;
}
html {
  background-color: #161a29;
}

.basic-canvas img {
  border-radius: 12px;
  align-self: center;
}

.column-container {
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.row-container {
  gap: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
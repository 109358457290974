@import url('https://fonts.googleapis.com/css?family=Outfit:light,medium,bold,italic&subset=latin,latin-ext');

html {
  background-color: #090b18;
}

.basic-canvas {
  background-color: #161a29;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  /*min(15px, calc(10px + 0.75vw));*/
  color: #ffffff;
}

.basic-column {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin-top: 25px;
  margin-bottom: 25px;
  max-width: 650px;
}

.horizontal-divider-line {
  background-color: #696969;
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  height: 1.5px
}

body {
  font-family: "Outfit";
  /* -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

h3 {
  margin-top: -5px;
  margin-bottom: 0;
}

p {
  margin: 0;
  font-weight: lighter;
}

ul {
  margin: 0;
}

li {
  font-weight: lighter;
}

span {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: lighter;
}

i {
  margin-top: 0;
  margin-bottom: 0;
}

a:link {
  color: #333c51;
  background-color: transparent;
  text-decoration: none;
  font-weight: 300;
  font-size: 16px;
}

a:visited {
  color: #333c51;
  background-color: transparent;
  text-decoration: none;
}

a:hover {
  color: #7d7f91;
  background-color: transparent;
  text-decoration: underline;
}

a:active {
  color: #7d7f91;
  background-color: transparent;
  text-decoration: underline;
}
/* body {
  font-family: "Outfit";
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
} */

.home-canvas {
  background-color: #090b18;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100svh;
  min-width: 100svw;
  color: #ffffff;
  /* box-shadow: 0 0 300px rgba(0, 0, 0, 0.7) inset; */
}

.home-column {
  display: flex;
  align-items: center;
  gap: calc(14px + 7vmin);
  flex-direction: column;
}

.logo-text-column {
  display: flex;
  flex-direction: column;
}

.large-paragraph {
  font-size: calc((3px + 1vmin)*4.3);
  line-height: calc((3px + 1vmin)*4.3);
}

.small-paragraph {
  font-size: calc((3px + 1vmin)*3.215);
  line-height: calc((3px + 1vmin)*3.215);
}

.logo-row {
  pointer-events: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: calc((3px + 1vmin)*2.3);
  font-weight: 500;
}

.logo-row img {
  width: calc((3px + 1vmin)*10);
}

.badge-row {
  display: flex;
  justify-content: center;
  width: 100%;
  gap: calc(6px + 2vmin);
}

.android-badge {
  height: calc(16.5px + 5.5vmin);
}

.ios-badge {
  height: calc(16.5px + 5.5vmin);
}

.badge-row img {
  height: 100%
}

/* .bottom-links {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 1.5em;
  position: absolute;
  top: 0.5em;
  right: 0.8em;
} */